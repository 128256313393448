@tailwind base;
@tailwind components;
@tailwind utilities;


.bubble {
  position: relative;
  background: #ffffff;
  color: #000000;
  width: 100%;
  height: auto;
  max-width: 600px;
}

/* .bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 0 20px 20px;
  top: -20px;
  left: 50%;
  margin-left: -20px;
} */

body {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('/dlkr-EJHiB8oRzYc-unsplash.jpg');
  background-size: cover;
}


@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');